import { useNavigate } from "react-router-dom";
import { AppAlertData } from "../store/storeslice/alert-slice";
import { useStoreSelector } from "../store/useStore";
import APIendPoint from "../utils/apiEndPoints";
import helpers from "../utils/helpers";

export default () => {
   const navigate = useNavigate();
   const { setAlert, setIsLoading, setTradeData, setTradeCount, setTradeEntryData, setTradeEntryCount } = useStoreSelector([
      "setAlert",
      "setIsLoading",
      "setTradeData",
      "setTradeCount",
      "setTradeEntryData",
      "setTradeEntryCount"
   ]);

   const createTradeEntry = async (
      data: {
         deal: string, achat_qty?: string, nxp_no?: string, app_no?: string, inspect_date?: string, container_no?: string, veritek_gross_weight?: string, veritek_dry_net?: string, first_payment?: string, second_payment?: string, rci_sn?: string, ship_abroad?: string, obl_sent?: string, estimated_delivery?: string, ahk_dry?: string, ahk_sample_date?: string, asi_final?: string, last_payment?: string
      },
      onPress?: Function
   ) => {
      if (!data?.deal) {
         return helpers.showToast("error", "deal title is required");
      }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/trade/entry`,
            method: "POST",
            json: {
               deal: data.deal,
               achat_qty: data?.achat_qty,
               nxp_no: data?.nxp_no,
               app_no: data?.app_no,
               insp_date_verit: data?.inspect_date, container_no: data?.container_no, veritek_gross_weight: data?.veritek_gross_weight,
               veritek_dry_weight: data?.veritek_dry_net,
               first_payment: data?.first_payment, second_payment: data?.second_payment,
               rci_sn_content: data?.rci_sn,
               shipped_abroad: data?.ship_abroad, obl_sent: data?.obl_sent, estimated_delivery: data?.estimated_delivery,
               ahk_dry_net: data?.ahk_dry,
               ahk_sample: data?.ahk_sample_date, asi_final_result: data?.asi_final, last_payment: data?.last_payment
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showToast("error", sendRequest.data.message);
      }
      getTradeEntry();
      helpers.showToast("success", "Entry was created successful");
      onPress && onPress();
   };
   const getTradeEntry = async (
      startDate = "",
      endDate = "",
      q = "",
      page = 1,
      perPage = 50
   ) => {
      setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/trade/entry?page=${page}&start_date=${startDate}&end_date=${endDate}&q=${q}&item_per_page=${perPage}`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false)
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }

      setTradeData(sendRequest.data.data);
   };
   const updateTradeEntry = async (
      data: {
         deal: string, achat_qty?: string, nxp_no?: string, app_no?: string, inspect_date?: string, container_no?: string, veritek_gross_weight?: string, veritek_dry_net?: string, first_payment?: string, second_payment?: string, rci_sn?: string, ship_abroad?: string, obl_sent?: string, estimated_delivery?: string, ahk_dry?: string, ahk_sample_date?: string, asi_final?: string, last_payment?: string
      },
      authID: string,
      onPress?: Function
   ) => {

      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/trade/entry/${authID}`,
            method: "PUT",
            json: {
               deal: data.deal,
               achat_qty: data?.achat_qty,
               nxp_no: data?.nxp_no,
               app_no: data?.app_no,
               insp_date_verit: data?.inspect_date, container_no: data?.container_no, veritek_gross_weight: data?.veritek_gross_weight,
               veritek_dry_weight: data?.veritek_dry_net,
               first_payment: data?.first_payment, second_payment: data?.second_payment,
               rci_sn_content: data?.rci_sn,
               shipped_abroad: data?.ship_abroad, obl_sent: data?.obl_sent, estimated_delivery: data?.estimated_delivery,
               ahk_dry_net: data?.ahk_dry,
               ahk_sample: data?.ahk_sample_date, asi_final_result: data?.asi_final, last_payment: data?.last_payment
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      getTradeEntry();
      helpers.showToast("success", "Entry was updated successful");
      onPress && onPress();
   };

   const deleteTradeEntry = async (id: string, onPress?: Function) => {
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/trade/entry/${id}`,
            method: "DELETE",
         })
         .catch((e) => e);
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      getTradeEntry();
      helpers.showToast("success", "Entry was deleted successful");
      onPress && onPress();
   };

   const getAllTradeEntry = async (
      page = 1,
   ) => {
      // helpers.showToast("loading", "Loading");
      setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/trade/entry?page=${page}&item_per_page=5`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false)
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showToast("error", sendRequest?.data?.message);
      }

      setTradeEntryData(sendRequest?.data?.data);
   };
   const getAllTradeEntryCount = async (
      page = 1,
   ) => {
      // helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/trade/entry?page=${page}&item_per_page=5&component=count`,
            method: "GET",
         })
         .catch((e) => e);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showToast("error", sendRequest?.data?.message);
      }
      setTradeEntryCount(sendRequest.data.data);
   };

   return {
      createTradeEntry,
      updateTradeEntry,
      getTradeEntry,
      deleteTradeEntry,
      getAllTradeEntry,
      getAllTradeEntryCount
   };
};
