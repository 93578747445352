import { Routes, RouteObject } from "react-router-dom"
import TradeIndex from "../pages/trade/TradeIndex"


export const TradeRoute: RouteObject = {
   path: "/trade",
   children: [
      {
         path: "/trade",
         element: <TradeIndex />
      },
      {
         path: "/trade/:id",
         element: <TradeIndex />
      },
   ]
}