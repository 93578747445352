import { Routes, RouteObject } from "react-router-dom"
import MineIndex from "../pages/mining/MineIndex"


export const MineRoute: RouteObject = {
   path: "/mining",
   children: [
      {
         path: "/mining",
         element: <MineIndex />
      },
      {
         path: "/mining/:id",
         element: <MineIndex />
      },
   ]
}