import { useNavigate } from "react-router-dom";
import { useStoreSelector } from "../store/useStore";
import APIendPoint from "../utils/apiEndPoints";
import helpers from "../utils/helpers";

export default () => {
   const navigate = useNavigate();
   const {
      setIsLoading,
      setUserData,
      setAdminData,
      setAdminPageCount,
   } = useStoreSelector([
      "setIsLoading",
      "setUserData",
      "setAdminData",
      "setAdminPageCount",
   ]);
   const login = async (
      email: string,
      password: string,
      setIsLoading: Function
   ) => {
      if (!email) {
         return helpers.showToast("error", "Please input a valid email");
      }
      if (!password) {
         return helpers.showToast("error", "Please input a valid password");
      }
      helpers.showToast("loading", "Loading please wait")
      setIsLoading(true);
      let sendRequest = await helpers
         .sendRequest({
            url: APIendPoint.admin.login,
            method: "POST",
            json: { email, password },
         })
         .catch((e) => e);

      if (sendRequest) {
         setIsLoading(false);
         if (sendRequest.status_code === 201) {
            helpers.showToast("success", "success")
            if (sendRequest.data.status === "reset-pass") {
               console.log(sendRequest)
               setUserData(sendRequest.data.data);
               navigate(`/login/${sendRequest.data.data.auth_id}`)
            }
         } else if (sendRequest.status_code === 200) {
            helpers.showToast("success", "success")
            setUserData(sendRequest.data.data);
            localStorage.setItem("__mtoken", sendRequest.data.data.token);
            localStorage.setItem("__mauth_id", sendRequest.data.data.auth_id);
            navigate("/gas");
         } else {
            // console.log(sendRequest)
            return helpers.showToast("error", sendRequest?.data ? sendRequest?.data?.message : "Looks like you are experiencing a network issue");
         }
      }
   };

   const getprofile = async (type?: string) => {
      let token = localStorage.getItem("__mtoken");
      let authId = localStorage.getItem("__mauth_id");
      if (!token) {
         // window.location.href = APIendPoint.landingP
         return;
      }
      let sendRequest = await helpers
         .sendRequest({
            url: APIendPoint.admin.profile,
            method: "GET",
            token: token,
         })
         .catch((e) => e);
      // return
      if (sendRequest && sendRequest.status_code !== 200) {
         localStorage.removeItem("__mtoken");
         // window.location.href = APIendPoint.landingP
         return;
         // return helpers.showErrorMessage(sendRequest);
      }
      setUserData({ ...sendRequest.data.data, token: token });
   };

   const fetchAdminList = async (page = 1) => {
      setIsLoading(true);
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/accounts?page=${page}&item_per_page=10`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }

      setAdminData(sendRequest.data?.data);
   };

   const fetchAdminCount = async (
      page = 1,
      startDate = "",
      endDate = "",
      q = ""
   ) => {
      setIsLoading(true);
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/accounts?component=count&page=${page}&start_date=${startDate}&end_date=${endDate}&q=${q}`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }

      //  console.log(sendRequest.data);
      setAdminPageCount(sendRequest.data.data);
   };

   type DropdownItems = {
      name?: string;
   };

   const createAdmin = async (
      data: {
         fullname: string;
         email?: string;
         role?: string;
         department?: string;
         password?: string;
      },
      onPress?: Function
   ) => {
      if (!data?.fullname) {
         return helpers.showToast("error", "admin name is required");
      }
      if (!data?.email) {
         return helpers.showToast("error", "admin email is required");
      }
      if (!data?.role) {
         return helpers.showToast("error", "role is required");
      }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/accounts`,
            method: "POST",
            json: {
               fullname: data?.fullname,
               email: data?.email,
               department: data?.department,
               role: data?.role,
               password: data.password,
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      fetchAdminList();
      helpers.showToast("success", "Admin was created successful");
      onPress && onPress();
   };
   const updateAdmin = async (
      data: {
         fullname: string;
         email?: string;
         role?: string;
         password?: string;
         department?: string;
      },
      onPress?: Function,
      adminID?: string
   ) => {
      if (data?.fullname) {
         if (data?.fullname.length < 2) {
            return helpers.showToast("error", "admin fullname looks invalid");
         }
      }
      if (!data?.email) {
         return helpers.showToast("error", "admin email is required");
      }
      if (!data?.role) {
         return helpers.showToast("error", "admin role is required");
      }
      //  return;
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/accounts/${adminID}`,
            method: "PUT",
            json: {
               fullname: data?.fullname,
               email: data?.email,
               role: data?.role,
               department: data?.department,
               password: data?.password,
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      fetchAdminList();
      helpers.showToast("success", "Admin was updated successful");
      onPress && onPress();
   };
   const deleteAdmin = async (adminID?: string, onPress?: Function) => {
      //  return;
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/accounts/${adminID}`,
            method: "DELETE",
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showToast("error", sendRequest.data.message);
      }
      fetchAdminList();
      helpers.showToast("success", "Admin was deleted successful");
      onPress && onPress();
   };

   const resetNewPassword = async (
      email: string,
      password: string,
      confirmPassword: string,
      onPress?: Function
   ) => {
      if (!email) {
         return helpers.showToast("error", "email was not captured");
      }
      if (!password) {
         return helpers.showToast("error", "password is required");
      }
      if (confirmPassword != password) {
         return helpers.showToast(
            "error",
            "password and confirm password do not match"
         );
      }

      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.pwdReset}/reset-password`,
            method: "POST",
            json: {
               email: email,
               password: password,
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      helpers.showToast("success", "Password was changed successfully");
      onPress && onPress();
   };
   const firstLogin = async (
      id: string,
      password: string,
      fullname: string,
      setIsLoading: Function
   ) => {
      if (fullname) {
         if (fullname.length < 2) {
            return helpers.showToast("error", "fullname looks invalid");
         }
      }
      if (!password) {
         return helpers.showToast("error", "password is required");
      }

      helpers.showToast("loading", "Loading");
      setIsLoading(true);
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/auths/first-login`,
            method: "POST",
            json: {
               fullname: fullname,
               auth_id: id,
               password: password,
            },
         })
         .catch((e) => e);
      if (sendRequest) {
         setIsLoading(false);
         if (sendRequest && sendRequest.status_code !== 200) {
            if (sendRequest) {
               return helpers.showToast("error", sendRequest.data?.message);
            } else {
               return helpers.showToast("error", "Please check your network and try again");
            }
         }
         helpers.showToast("success", "Login was successful");
         setUserData(sendRequest.data.data);
         localStorage.setItem("__mtoken", sendRequest.data.data.token);
         localStorage.setItem("__mauth_id", sendRequest.data.data.auth_id);
         navigate("/guide");
      }
   };

   const updateAccount = async (
      data: {
         fullname?: string;
      },
      onPress?: Function
   ) => {
      if (data?.fullname) {
         if (data?.fullname.length < 2) {
            return helpers.showToast("error", "admin fullname looks invalid");
         }
      }
      //  return;
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/accounts/update`,
            method: "PUT",
            json: {
               fullname: data?.fullname,
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      getprofile();
      helpers.showToast("success", "Account was updated successful");
      onPress && onPress();
   };
   const updateAccountPassword = async (
      old_password: string,
      new_password: string,
      confirm_password: string,
      onPress?: Function
   ) => {
      if (!old_password) {
         return helpers.showToast("error", "Old Password is required");
      }
      if (!new_password) {
         return helpers.showToast("error", "New Password is required");
      }
      if (!confirm_password) {
         return helpers.showToast("error", "Comfirm Password is required");
      }
      if (new_password !== confirm_password) {
         return helpers.showToast("error", "Comfirm Password and new password did not match");
      }
      //  return;
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/accounts/update`,
            method: "PUT",
            json: {
               old_password: old_password,
               new_password: new_password
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }

      helpers.showToast("success", "Password reset was successful");
      onPress && onPress();
      localStorage.removeItem('__mtoken')
      localStorage.removeItem('__mauth_id')
      setTimeout(() => {
         window.location.href = APIendPoint.landingP
      }, 3000)
   };

   return {
      login,
      getprofile,
      fetchAdminList,
      fetchAdminCount,
      createAdmin,
      resetNewPassword,
      updateAdmin,
      deleteAdmin,
      firstLogin,
      updateAccount,
      updateAccountPassword
   };
};
