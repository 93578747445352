import React, { useState } from 'react'
import { Properties as CSSProperties } from 'csstype'
import { ObjectPayload } from '../typings/general';
import { NavLink, useNavigate } from 'react-router-dom';
import APIendPoint from '../utils/apiEndPoints';

export type DataState = {
   data?: ObjectPayload;
   onPress?: Function;
   onSubmit?: Function;
   containerStyle?: CSSProperties;
}
export default (props: DataState) => {
   const Navigate = useNavigate()
   const LogOut = () => {
      localStorage.removeItem('__mtoken')
      localStorage.removeItem('__mauth_id')
      window.location.href = APIendPoint.landingP
   }
   return (
      <div style={{
         position: 'fixed', left: 0, top: 0,
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         backgroundColor: 'rgba(0, 0, 0, 0.3)',
         backgroundSize: "cover",
         width: '100%',
         height: '100vh',
         zIndex: 999,
      }}>
         <div style={{ borderRadius: '20px', padding: '20px', width: '400px', ...props.containerStyle }}>
            <div className='bg-white' style={{ borderRadius: "20px" }}>
               <div className="filterForm w-100">
                  <div className='popupClass1'>
                     <div className="modal-content">
                        <div className="modal-body" style={{ marginTop: "0", paddingBottom: "0" }}>
                           <div className="mb-3 d-block">
                              <div className="d-flex align-items-center justify-content-between flex-wrap">
                                 <div>
                                    <h3 className="modal-title fs-5 delete-header" id="exampleModalLabel">Logout</h3>
                                 </div>

                              </div>
                           </div>
                           <div className="mb-3 d-block">
                              <label className="form-label d-block mb-2">You are about to exit the data centre, Are sure you will  like to proceed?</label>
                           </div>
                        </div>
                        <div className="modal-footer fotterFlex d-flex align-items-center justify-content-between flex-wrap" style={{ marginTop: "-10px" }}>
                           {/* <button type="button" className="btn btn-lg light primaryBG2 ww-50" onClick={() => props.onPress && props.onPress()}>Back</button>
                           <button type="button" className="btn btn-lg primaryBG ww-50" onClick={() => props.onSubmit && props.onSubmit(props?.data?.auth_id, props.onPress)}>Logout</button> */}

                           <p style={{ marginTop: "-12px" }}><NavLink to="#" onClick={() => props.onPress && props.onPress()} className="text-primary textGreen font-w500 showCursor">Cancel</NavLink></p>
                           <NavLink onClick={() => LogOut()} className="btn btn-primary mb-4 btnGreen bgbtn" to="#">Logout</NavLink>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </div>
   )
}